import React from 'react'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import { CardContainer } from '../ManageLeaders/styles'
import { CardHeader } from '../ManageLeaders/styles'
import styled from 'styled-components'
import { formatUnixTimestampToDateTimeWithSeconds } from '../../../../utils/time'

const SessionGrid = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 0.5rem;
  padding-top: 1rem;
`

const GridHeader = styled.div`
  font-weight: bold;
`

const AgentSessions = () => {
  const [sessions, setSessions] = React.useState([])
  const [show, setShow] = React.useState({})

  React.useEffect(() => {
    getSessions()
  }, [])

  const getSessions = async () => {
    const response = await getHttpRequest('/get_agent_sessions')
    setSessions(response.agentSessions)
  }

  const sendSignal = async (encodedSessionId, signal) => {
    await postHttpRequest(`/send_signal_to_agent/${encodedSessionId}`, {
      signal: signal,
    })
  }

  const toggleControls = (encodedSessionId) => {
    setShow((prev) => ({
      ...prev,
      [encodedSessionId]: prev?.[encodedSessionId] === undefined ? true : !prev[encodedSessionId],
    }))
  }

  return (
    <CardContainer basis="100%">
      <CardHeader>Agent Sessions</CardHeader>
      <SessionGrid>
        <GridHeader>User Name</GridHeader>
        <GridHeader>Agent Type</GridHeader>
        <GridHeader>Created on</GridHeader>
        <GridHeader>Button</GridHeader>
        {sessions
          .sort((a, b) => b.timestampStarted - a.timestampStarted)
          .map((session, index) => (
            <React.Fragment key={`leaders-${index}`}>
              <div>{session.agentName}</div>
              <div>{session.agentType}</div>
              <div>{formatUnixTimestampToDateTimeWithSeconds(session.timestampStarted)}</div>
              <div>
                <button onClick={() => toggleControls(session.encodedSessionId)}>
                  {show?.[session.encodedSessionId] ? 'Hide' : 'Show'}
                </button>
              </div>
              {show?.[session.encodedSessionId] && (
                <React.Fragment>
                  <div style={{ gridColumn: '1 / -1' }}>
                    <button
                      onClick={() => sendSignal(session.encodedSessionId, { shouldPause: true })}
                    >
                      Pause
                    </button>
                    <button
                      style={{ marginLeft: '3rem' }}
                      onClick={() => sendSignal(session.encodedSessionId, { shouldPause: false })}
                    >
                      UnPause
                    </button>
                    <button
                      style={{ marginLeft: '3rem' }}
                      onClick={() => sendSignal(session.encodedSessionId, { revert: true })}
                    >
                      Revert
                    </button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
      </SessionGrid>
    </CardContainer>
  )
}

export default AgentSessions
