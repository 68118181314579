const routes = {
  teamleader: 'TeamLeader',
  areaLeader: 'AreaLeader',
  governance: 'Governance',
  meetingview: 'MeetingView',
  meetingMultiView: 'MeetingMultiView',
  teamMeetingview: 'TeamMeetingView',
  leadership: 'Leadership',
  setupwizard: 'SetupWizard',
  home: 'Home',
  update: 'Update',
  objectivemap: 'ObjectiveMap',
  organizationstrategy: 'OrganizationStrategy',
  setupcomplete: 'SetUpComplete',
  demandintake: 'DemandIntake',
  capacity: 'Capacity',
  kanban: 'Kanban',
  objectives: 'Objectives',
  meetingDeck: 'MeetingDeck',
  sankey: 'Sankey',
  emailReminder: 'EmailReminder',
  cxomgmtscore: 'CXOMgmtScore',
  cxomgmtscoreL2Beta: 'CxoMgmtScoreL2Beta',
  searchTest: 'SearchTest',
  printMode: 'PrintMode',
  notifications: 'Notifications',
  kpiReportByLeader: 'KpiReportByLeader',
  changeLog: 'ChangeLog',
  excoExercise: 'AIWorkshop',
  workshopAspirations: 'WorkshopAspirations',
  workshopFinalAspirations: 'WorkshopFinalAspirations',
  workshopAdminAspirationsView: 'workshopAdminAspirationsView',
  odinInbox: 'OdinInbox',
  agedItemsRanking: 'AgedItemsRanking',
  kpi: 'KPI',
  companySetUpAgent: 'CompanySetUpAgent',
  companySetUpAgentSocket: 'SetupConvo',
  companySetUpAgentSocketWithPlugin: 'AIConsultantSetupStep1',
  teamSetUpAgentSocket: 'TeamSetupConvo',
  teamSetUpAgentSocketWithPlugin: 'AIConsultantSetupStep2',
  presentation: 'Presentation',
  teamLeaderSetupAgent: 'TeamLeaderSetupAgent',
  setupIntroScreen: 'SetupIntro',
  kpiListOfObjectives: 'KPI/ListOfObjectives',
  myNotebook: 'MyNotebook',
  changesReport: 'Report',
  reviewObjectiveMap: 'ReviewObjectiveMap',
  summaryOfObjectives: 'SummaryOfObjectives',
  l2SetupConsultant: 'AIConsultantSetupL2',
  l3SetupConsultant: 'AIConsultantSetupL3',
}

const demandIntakeSubRoutes = {
  intro: 'Intro',
  chooseBauObjective: 'ChooseBauObjective',
  f2wAvailability: 'F2wAvailability',
  objectivesTeamMapping: 'ObjectivesTeamMapping',
  teamCapacityAllocation: 'TeamCapacityAllocation',
  additionalCapacity: 'AdditionalCapacity',
}

const capacitySubRoutes = {
  f2wAvailability: 'F2wAvailability',
  objectivesTeamMapping: 'ObjectivesTeamMapping',
  teamCapacityAllocation: 'TeamCapacityAllocation',
  additionalCapacity: 'AdditionalCapacity',
}

const updateSubRoutes = {
  intro: 'Intro',
  activities: 'Activities',
  objectivesTeamMapping: 'ObjectivesTeamMapping',
  teamCapacityAllocation: 'TeamCapacityAllocation',
  additionalCapacity: 'AdditionalCapacity',
  blockers: 'Blockers',
  meetingTopics: 'MeetingTopics',
  allMyActions: 'AllMyActions',
  overdueKPIs: 'OverdueKPIs',
}

const kpiSubRoutes = {
  intro: 'Intro',
  teamOverview: 'TeamOverview',
  listOfObjectives: 'ListOfObjectives',
  timeframe: 'Timeframe',
  longtermQuestion: 'LongtermQuestion',
  shorttermQuestion: 'ShorttermQuestion',
  suggestedKpis: 'SuggestedKPIs',
}

const meetingViewSubRoutes = {
  tradeOffs: 'TradeOffs',
  dependencyResolution: 'DependencyResolution',
  F2WAssignment: 'F2WAssignment',
  overview: 'MilestonesOverview',
  overAllocation: 'OverAllocation',
  trendsAnalysis: 'trendsAnalysis',
}

const teamMeetingViewSubRoutes = {
  overview: 'MilestonesOverview',
  meetingTopics: 'MeetingTopics',
  leadershipDecisions: 'LeadershipDecisions',
  kpiReportByLeader: 'KpiReportByLeader',
  meetingSummaries: 'MeetingSummaries',
}

const meetingMultiViewSubRoutes = {
  tradeOffs: 'TradeOffs',
  dependencyResolution: 'DependencyResolution',
  F2WAssignment: 'F2WAssignment',
  overview: 'MilestonesOverview',
  overAllocation: 'OverAllocation',
  takeaways: 'Takeaways',
  trendsAnalysis: 'TrendsAnalysis',
  meetingTopics: 'MeetingTopics',
  decisionLog: 'DecisionLog',
  myDecisions: 'MyDecisions',
  kpiReportByLeader: 'KpiReportByLeader',
  meetingSummaries: 'MeetingSummaries',
}

const governanceSubRoutes = {
  manageTeamLeader: 'ManageTeamLeaders',
  manageObjectives: 'ManageObjectives',
  manageCadences: 'ManageCadences',
  governanceStatus: 'Status',
  enterpriseMetrics: 'EnterpriseMetrics',
  peopleManagment: 'PeopleManagment',
  manageTeamConfiguration: 'MangageTeamConfiguration',
  initiativeMangement: 'InitiativeManagement',
  accessConfig: 'UserConfigurations',
  classifyMeetingTopics: 'ClassifyMeetingTopics',
  logMei: 'LogMei',
  support: 'Support',
  companyConfig: 'CompanyConfig',
  meiReports: 'MeiReports',
  seeOthersProfile: 'SeeOthersProfile',
  companyInfo: 'CompanyInfo',
  agentSession: 'AgentSession',
}

const setupWizardSubRoutes = {
  intro: 'Intro',
  enterpriseLeaders: 'EnterpriseLeader',
  areaLeaders: 'AreaLeader',
  teamLeaders: 'TeamLeader',
  individualContributor: 'IndividualContributor',
  governanceConfig: 'GovernanceConfig',
  topLevelObjectives: 'TopLevelObjectives',
  areaLevelObjectives: 'AreaLevelObjectives',
  impactMeasures: 'ImpactMeasures',
  setUpWizardEnd: 'SetUpWizardEnd',
  caseMapping: 'CaseMapping',
}

const teamLeaderSubRoutes = {
  intro: 'Intro',
  team: 'Team',
  objectives: 'Objectives',
  activities: 'Activities',
  enterprise: 'Enterprise',
  capacity: 'Capacity',
  meetingTopics: 'MeetingTopics',
  externalMembers: 'ExternalMembers',
  objectivesTeamMapping: 'ObjectivesTeamMapping',
  teamCapacityAllocation: 'teamCapacityAllocation',
  additionalCapacity: 'AdditionalCapacity',
}

const areaLeaderSubRoutes = {
  intro: 'Intro',
  objectives: 'Objectives',
  type: 'Type',
  linkage: 'Linkage',
  meetingTopics: 'MeetingTopics',
}

const myNotebookSubRoutes = {
  notes: 'Notes',
  tasks: 'Tasks',
}

const changesReportSubRoutes = {
  actions: 'Actions',
  kpis: 'KPIs',
  meetingTopics: 'MeetingTopics',
}

const excoExerciseSubRoutes = {
  intro: 'Intro',
  question1: 'Question1',
  question2: 'Question2',
  question3: 'Question3',
  results: 'Results',
}

export {
  routes,
  teamLeaderSubRoutes,
  areaLeaderSubRoutes,
  governanceSubRoutes,
  meetingViewSubRoutes,
  setupWizardSubRoutes,
  updateSubRoutes,
  demandIntakeSubRoutes,
  teamMeetingViewSubRoutes,
  meetingMultiViewSubRoutes,
  capacitySubRoutes,
  excoExerciseSubRoutes,
  kpiSubRoutes,
  myNotebookSubRoutes,
  changesReportSubRoutes,
}
