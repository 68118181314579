import React from 'react'
import useLoginData from '../../api/query/useLoginData'
import InPageLoader from '../../components/InPageLoader'
import {
  saveCredsToLocalStorage,
  hasRole,
  clearLocalStorage,
  getHasSetupWizardAction,
  getConsolidatedConfigSettings,
  getCompanyConfigSettings,
} from '../../utils/auth.js'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { useNavigate } from 'react-router-dom'
import { kpiSubRoutes, routes, updateSubRoutes } from '../../utils/routes'

const CLogin = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { data: loginData, isLoading: isLoginLoading, error: isLoginError } = useLoginData()

  const queryParams = new URLSearchParams(location.search)
  const next = queryParams.get('next')

  if (isLoginLoading) {
    return <InPageLoader />
  }
  clearLocalStorage()
  if (saveCredsToLocalStorage(loginData)) {
    const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5
    const enableSetupAgent = getConsolidatedConfigSettings('enable_setup_agent') === true
    const resolution = `${window.screen.width}x${window.screen.height}`
    const productSettings = getCompanyConfigSettings('productSettings')

    Sentry.setUser({
      id: localStorage.getItem('tfei'),
      screen_resolution: resolution,
    })

    Sentry.setTag('user_corr_id', localStorage.getItem('tfei'))
    if (next === 'DecisionLogDigest') {
      const digestId = queryParams.get('digest_id')
      const token = queryParams.get('token')

      window.location.href = '/DecisionLogDigest?digest_id=' + digestId + '&token=' + token
    } else if (next === 'ActionsDigest') {
      const digestId = queryParams.get('digest_id')
      const token = queryParams.get('token')

      window.location.href = '/digest/' + digestId + '?token=' + token
    } else if (next === 'ActionsDigestV2') {
      window.location.href = '/EmailReminder'
    } else if (next === 'UpdateWizardDigest') {
      window.location.href = '/Update'
    } else if (next === 'OdinInboxDigest') {
      let trackingId = queryParams.get('tracking_id')
      if (trackingId) {
        window.location.href = '/OdinInbox?trackingId=' + trackingId
      } else {
        window.location.href = '/OdinInbox'
      }
    } else if (next === 'SeeOthersProfile') {
      window.location.href = '/Governance/SeeOthersProfile'
    } else {
      if (hasRole('aspirations_redir')) {
        window.location.href = '/WorkshopAspirations'
      } else if (hasRole('ic')) {
        if (productSettings) {
          window.location.href = '/SummaryOfObjectives'
        } else {
          window.location.href = '/Kanban'
        }
      }
      // Commenting this out as we don't want them to directly land on Setup Page
      // else if (getHasSetupWizardAction() && enableSetupAgent) {
      //   navigate(`/${routes.setupIntroScreen}`, {
      //     state: {
      //       teamLeaderIntro: true,
      //     },
      //   })
      // }
      else if (
        hasRole('cxo') ||
        hasRole('area') ||
        hasRole('ceo') ||
        hasRole('cos') ||
        (hasRole('team') && isDelegateTl)
      ) {
        // We use initialLoad to check if the user is logging in for the first time.
        // Other redirects to Home will not have initialLoad as a query parameter.
        if (localStorage.getItem('send_to_setup_agent') === 'true' && hasRole('area')) {
          window.location.href = '/CompanySetUpAgent'
          return <></>
        }
        window.location.href = '/MeetingMultiView?initialLoad=true'
      }
      // Commenting this out as we don't want them to directly land on Setup Page
      // else if (hasRole('team')) {
      //   if (localStorage.getItem('is_demo') === 'true') {
      //     window.location.href = '/SetupIntro'
      //     return <></>
      //   }
      //   window.location.href = '/MeetingMultiView?initialLoad=true'
      // }
      else if (hasRole('enterprise') || hasRole('admin')) {
        window.location.href = '/Governance'
      } else if (hasRole('workshop_user')) {
        window.location.href = '/AIWorkshop'
      } else {
        window.location.href = '/ObjectiveMap'
      }
    }
  } else {
    localStorage.setItem('hrefReason_9A', '0x9A')
    window.location.href = '/'
  }

  return <></>
}

export default CLogin
